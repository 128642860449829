export default {
  control: (provided) => ({
    ...provided,
    background: "transparent",
    fontFamily: "Satoshi",
    padding: "10px 15px",
    borderRadius: "100px"
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: "200px"
  }),
  option: (provided) => ({
    ...provided,
    fontFamily: "Satoshi"
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: "bold",
    color: "black"
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontWeight: "bold",
    color: "black"
  })
}