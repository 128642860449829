// NPM
import React from 'react'
import _ from 'lodash'

const NewsItem = ({ section, newsItem }) => {
  const sourceLinkLabel = (section.miscText.find(mt => mt.uuid === "sourceLinkLabel") || {}).text

  return (
    <div className="NewsItem">
      <img 
        className="NewsItem__image"
        src={ _.get(newsItem, `image.url`) } 
        alt={ newsItem.imageAlt }
      />

      <div className="NewsItem__source">
        <a 
          className="NewsItem__source-name"
          href={ newsItem.sourceUrl } 
          target="_blank">
            { newsItem.sourceName }
        </a>

        <div className="NewsItem__source-date">
          { newsItem.date }
        </div>
      </div>

      <a 
        className="NewsItem__headline"
        href={ newsItem.sourceUrl } 
        target="_blank">
          { newsItem.headline }
      </a>

      <a 
        className="NewsItem__link"
        href={ newsItem.sourceUrl } 
        target="_blank">
          { sourceLinkLabel }
      </a>
    </div>
  )

}

export default NewsItem