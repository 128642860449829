// NPM
import React, { useState, useEffect } from 'react'
import _ from 'lodash'

// Constants
const defaultPerPage = 6.0

const Pagination = ({ items, perPage, children }) => {
  const [currentPage, setCurrentPage] = useState(0)

  // If the number of items changes, reset the currentPage because the list was just filtered
  useEffect(() => {
    setCurrentPage(0)
  }, [items.length])

  if(typeof children !== "function") {
    return null
  }

  const effectivePerPage = (perPage || defaultPerPage)

  const startIndex = currentPage * effectivePerPage
  const endIndex = startIndex + effectivePerPage
  const visibleItems = _.slice(items, startIndex, endIndex)
  const numPages = Math.ceil(items.length / effectivePerPage)
  
  const renderNavigation = () => {
    const pages = _.range(0, numPages)

    if(numPages === 1) {
      return null
    }

    return (
      <div className="Pagination__navigation">
        {
          currentPage > 0 &&
            <a 
              onClick={ () => setCurrentPage(currentPage - 1) }
              className={ `Pagination__navigation-prev` }>
                <img src={ require("src/images/icons/caret-left-black.png").default } />
            </a>  
        }

        { 
          currentPage === 0 &&
            <span className={ `Pagination__navigation-prev` }>
              <img src={ require("src/images/icons/caret-left-grey.png").default } />
            </span> 
        }
        {
          pages.map(pageNum => {
            return (
              <a 
                key={ `page-${ pageNum }` }
                onClick={ () => setCurrentPage(pageNum) }
                className={ `Pagination__navigation-page ${ currentPage === pageNum ? "active" : "" }` }>
                  { pageNum + 1 }
              </a>
            )
          })  
        }

        {
          currentPage < (numPages - 1) &&
            <a 
              onClick={ () => setCurrentPage(currentPage + 1) }
              className={ `Pagination__navigation-prev` }>
                <img src={ require("src/images/icons/caret-right-black.png").default } />
            </a>  
        }

        { 
          currentPage === (numPages - 1) &&
            <span className={ `Pagination__navigation-prev` }>
              <img src={ require("src/images/icons/caret-right-grey.png").default } />
            </span> 
        }
      </div>
    )
  }

  return (
    <div className="Pagination">
      { children(visibleItems) }
      { renderNavigation() }
    </div>
  )
}

export default Pagination