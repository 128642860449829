// NPM
import React from 'react'

// Shared Components
import Description from 'src/components/Shared/Description'
import CommonLink from 'src/components/Shared/CommonLink'
import NewsItems from 'src/components/Shared/NewsItems'

const GenericProduct = ({ sections, newsItems }) => {
  const productSection = sections.find(s => s.uuid === "productSection")

  if(!productSection) {
    return null
  }

  const renderProductLogo = () => {
    const productLogo = productSection.media.find(m => m.uuid === "productLogo")
    
    if(!productLogo) {
      return null
    }

    return (
      <div className="GenericProduct__logo">
        <img src={ productLogo.image.url } alt={ productLogo.imageAlt } />
      </div>
    )
  }

  const renderProductImage = () => {
    const productImage = productSection.media.find(m => m.uuid === "productImage")
    
    if(!productImage) {
      return null
    }

    return (
      <div className="GenericProduct__image">
        <img src={ productImage.image.url } alt={ productImage.imageAlt } />
      </div>
    )
  }

  const newsSection = sections.find(s => s.uuid === "newsItems")

  return (
    <div className="GenericProduct">
      { renderProductLogo() }

      <h1 className="GenericProduct__title">
        { productSection.title }
      </h1>

      <Description
        className="GenericProduct__description"
        description={ productSection.description }
      />

      <CommonLink 
        link={ productSection.link } 
        className="GenericProduct__link">
          <span className="icon">
            <img src={ require("src/images/icons/arrow-right-white.png").default } />
          </span>
      </CommonLink>

      { renderProductImage() }

      {
        newsSection &&
          <NewsItems 
            section={ newsSection }
            newsItems={ newsItems }
            newsItemSearchKey={ (newsSection.miscText.find(mt => mt.uuid === "newsItemSearchKey") || {}).text }
          />
      }
    </div>
  )
}

export default GenericProduct