// NPM
import React, { useState } from 'react'
import Select from 'react-select'
import _ from 'lodash'

// Shared components
import Pagination from "src/components/Shared/Pagination"

// Components
import NewsItem from './NewsItem'

// Constants
import reactSelectStyles from 'src/components/Shared/reactSelectStyles'

const NewsItems = ({ section, newsItems, newsItemSearchKey }) => {
  const [searchOptions, setSearchOptions] = useState({})

  if(!section) {
    return null
  }

  const filteredNewsItems = (newsItems || []).filter(ni => ni.product === newsItemSearchKey)
  if(filteredNewsItems.length === 0) {
    return null
  }

  const visibleNewsItems = 
    filteredNewsItems
    .filter(nI => {
      if(searchOptions.region && searchOptions.region.value) {
        return nI.region === searchOptions.region.value
      }
      else {
        // Not filtering by year, return all
        return true
      }
    })
    .filter(nI => {
      if(searchOptions.subRegion && searchOptions.subRegion.value) {
        return nI.subRegion === searchOptions.subRegion.value
      }
      else {
        // Not filtering by year, return all
        return true
      }
    })

  const articleCountLabelSingular = (section.miscText.find(mt => mt.uuid === "articleCountLabelSingular") || {}).text
  const articleCountLabelPlural = (section.miscText.find(mt => mt.uuid === "articleCountLabelPlural") || {}).text
  const countryDropdownPlaceholder = (section.miscText.find(mt => mt.uuid === "countryDropdownPlaceholder") || {}).text
  const regionDropdownPlaceholder = (section.miscText.find(mt => mt.uuid === "regionDropdownPlaceholder") || {}).text
  const sourceLinkLabel = (section.miscText.find(mt => mt.uuid === "sourceLinkLabel") || {}).text

  const renderRegionDropdown = () => {
    const newsItemsToUse = searchOptions.subRegion && searchOptions.subRegion.value ? visibleNewsItems : filteredNewsItems
    const uniqRegions = _.uniq(newsItemsToUse.map(nI => nI.region))
    const options = 
      uniqRegions
      .sort()
      .map(region => {
        return {
          label: region, 
          value: region
        }
      })

    const placeholderText = (section.miscText.find(mt => mt.uuid === "regionDropdownPlaceholder") || {}).text
    const allText = (section.miscText.find(mt => mt.uuid === "dropdownAllText") || {}).text

    return (
      <Select 
        options={[
          { label: allText, value: -1 },
          ...options
        ]} 
        value={ searchOptions.region }
        placeholder={ placeholderText }
        className="NewsItems__search-select"
        onChange={ selectedOption => { 
          if(selectedOption) {
            if(selectedOption.value === -1) {
              setSearchOptions({...searchOptions, region: null}) 
            }
            else {
              setSearchOptions({...searchOptions, region: selectedOption}) 
            }
          }
        }}
        styles={ reactSelectStyles }
      />
    )
  }

  const renderSubRegionDropdown = () => {
    const newsItemsToUse = searchOptions.region && searchOptions.region.value ? visibleNewsItems : filteredNewsItems
    const uniqSubRegions = _.uniq(newsItemsToUse.map(nI => nI.subRegion))
    const options = 
      uniqSubRegions
      .filter(subRegion => subRegion)
      .sort()
      .map(subRegion => {
        return {
          label: subRegion, 
          value: subRegion
        }
      })

    if(options.length === 0) {
      return null
    }

    const placeholderText = (section.miscText.find(mt => mt.uuid === "subRegionDropdownPlaceholder") || {}).text
    const allText = (section.miscText.find(mt => mt.uuid === "dropdownAllText") || {}).text

    return (
      <Select 
        options={[
          { label: allText, value: -1 },
          ...options
        ]} 
        value={ searchOptions.subRegion }
        placeholder={ placeholderText }
        className="NewsItems__search-select"
        onChange={ selectedOption => { 
          if(selectedOption) {
            if(selectedOption.value === -1) {
              setSearchOptions({...searchOptions, subRegion: null}) 
            }
            else {
              setSearchOptions({...searchOptions, subRegion: selectedOption}) 
            }
          }
        }}
        styles={ reactSelectStyles }
      />
    )
  }

  return (
    <div className="NewsItems">
      <div className="NewsItems__top">
        <h4 className="NewsItems__title">
          { section.title }
        </h4>
        <div className="NewsItems__search">
          <div className="NewsItems__search-count">
            { visibleNewsItems.length } { visibleNewsItems.length > 1 ? articleCountLabelPlural : articleCountLabelSingular }
          </div>

          <div className="NewsItems__search-items">
            { renderRegionDropdown() }
            { renderSubRegionDropdown() }
          </div>
        </div>
      </div>

      <Pagination items={ visibleNewsItems }>
        {
          (items) => {
            return (
              <div className="NewsItems__content">
                {
                  _.sortBy(items, "date").reverse().map((newsItem, newsItemIndex) => {
                    return (
                      <NewsItem
                        key={ `NewsItem-${ newsItemIndex }` }
                        newsItem={ newsItem }
                        section={ section }
                      />
                    )
                  })
                }
              </div>
            )
          }
        }
      </Pagination>
      
    </div>
  )

}

export default NewsItems